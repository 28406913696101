.purchase {
  &--loading {
    min-height: 100vh;
  }
  .container {
    width: 100%;
    padding: 0.5em;
    gap: 1em;
    display: flex;

    @media (max-width: 900px) {
      flex-direction: column;
    }
  }
}

.purchase__lists {
  display: flex;
  flex-direction: column;
  max-width: 50rem;
  width: 100%;
  gap: 0.5em;
  overflow-y: auto;
  max-height: 100vh;

  &.disabled {
    pointer-events: none;
    user-select: none;
    filter: brightness(70%);
  }

  > label {
    display: flex;
    gap: 1em;
    width: 100%;

    &:hover {
      .purchase__lists__checkbox::after {
        background-color: rgb(19, 81, 206);
      }
    }
  }

  .display-none {
    display: none;
  }

  &__checkbox {
    width: 0.5rem;
    height: 0.5rem;
    margin: auto 0;
    border: 1px solid var(--clr-line);
    border-radius: 50%;
    flex-shrink: 0;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 1000px) {
      display: none;
    }

    &::after {
      width: 100%;
      height: 100%;
      content: "";
      border-radius: 50%;
    }
    &--active::after {
      background-color: rgb(98, 168, 226);
    }
  }
}

.purchase__card {
  display: flex;
  gap: 1em;
  width: 100%;
  align-items: center;
  cursor: pointer;
  background-color: #f8f8f8;
  position: relative;
  border: 1px solid rgb(211, 214, 226);
  padding-right: 1em;

  &--active {
    background-color: #ffffff;
  }

  &__img {
    flex-shrink: 0;
    width: 6em;
    aspect-ratio: 7/10;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__text {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.2em;

    &__model {
      font-size: 0.8em;
      @media (max-width: 400px) {
        display: none;
      }
    }
  }

  &__btn-box {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    align-items: center;
    max-width: 8rem;
    flex-shrink: 0;

    > label {
      display: flex;
      align-items: center;
      gap: 0.5em;
      font-size: 0.8em;
      height: 2rem;

      input {
        font-size: initial;
        width: 2em;
        text-align: center;
        border: 1px solid var(--clr-line);
        outline: none;
        &:disabled {
          background-color: rgb(185, 185, 185);
          &:hover {
            cursor: not-allowed;
          }
        }

        &:focus {
          background-color: var(--clr-focus);
        }
      }
    }
  }
  &__btn {
    width: 100%;
    padding: 0.5em 1em;
    border: 1px solid var(--clr-line);
    border-radius: 2px;
    font-size: 0.8em;
    height: 2rem;

    &--active {
      background-color: #95c4eb;
      &:hover {
        background-color: #559ee2 !important;
      }
    }

    &:hover {
      background-color: #b6b6b6;
    }
  }

  &__delete {
    flex-shrink: 0;
    height: 100%;
    width: 2em;
    background-color: rgb(214, 214, 214);
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 1em;
      height: 1em;
      rect {
        fill: rgb(34, 34, 34);
      }
    }

    &:hover {
      background-color: rgb(180, 180, 180);
    }
  }
}

.purchase__form {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1em;
  background-color: #fff;
  padding: 1em;
  margin-bottom: auto;

  &__progress {
    display: flex;
    gap: 1em;
    position: relative;

    &__label {
      flex: 1 1 100%;
      padding: 0.5em;
      text-align: center;
      z-index: 2;
      background-color: rgb(219, 219, 219);
      border-radius: 4px;
      transition: 0.3s background-color;
      font-size: 0.9em;
      display: flex;
      align-items: center;
      justify-content: center;
      &.active {
        background-color: rgb(144, 183, 235);
      }
    }
    &__line {
      width: 100%;
      height: 2px;
      background-color: rgb(219, 219, 219);
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__content {
    overflow: hidden;
  }

  &__step {
    display: flex;
    width: 100%;
    position: relative;
    left: 0;
    transition: 0.3s left ease-in-out;

    &__page {
      flex-shrink: 0;
      width: 100%;
      gap: 1em;
      display: flex;
      flex-direction: column;
      padding: 1em;

      h2 {
        font-weight: bold;
        font-size: 1.2em;
        text-align: center;
      }
    }
  }

  &__invoice {
    display: flex;
    flex-direction: column;
    gap: 1em;

    &__box {
      display: flex;
      flex-direction: column;
      height: 8rem;
      overflow-y: auto;
    }

    &__item {
      border: 1px solid var(--clr-line);
      display: grid;
      grid-template-columns: repeat(1, 1fr 2em 6em);

      &:not(:nth-of-type(1)) {
        border-top: none;
      }

      > p {
        padding: 0.2em;
        height: 100%;
        display: flex;
        align-items: center;

        &:nth-of-type(1) {
          flex: 0 70%;
        }
        &:nth-of-type(2) {
          border-left: 1px solid var(--clr-line);
          justify-content: center;
        }
        &:nth-of-type(3) {
          border-left: 1px solid var(--clr-line);
          justify-content: right;
        }
      }
    }
  }

  &__total {
    span {
      display: inline-block;
      width: 8rem;
    }
  }

  &__next {
    display: flex;
    gap: 0.5em;
    justify-content: space-between;

    button {
      padding: 0.5em 2em;
      background-color: rgb(144, 183, 235);
      border-radius: 4px;
      color: black;
      font-size: 0.9em;
      &:hover {
        filter: opacity(0.6);
      }
      &:disabled {
        filter: opacity(0.6);
        cursor: not-allowed;
      }
    }
  }

  &__user {
    &__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: auto 0;
      gap: 1em;
      max-width: 16rem;
      margin: auto;
      text-align: center;

      a {
        padding: 0.5em 1em;
        border-radius: 4px;
        background-color: var(--clr-dark);
        color: #fff;
        &:hover {
          background-color: #3b3b3b;
        }
      }
    }
  }
}
