.card {
  min-height: 60vh;
  width: 100%;
  &__content {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, minmax(260px, 1fr));
    gap: 0.5em;
    justify-items: center;
    row-gap: 2em;
    @media (max-width: 1350px) {
      grid-template-columns: repeat(3, minmax(120px, 1fr));
    }
    @media (max-width: 500px) {
      grid-template-columns: repeat(2, minmax(120px, 1fr));
    }
    &__item {
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      cursor: pointer;
      &:hover {
        // outline: 1px solid rgb(168, 168, 168);
        h3 {
          text-decoration: underline;
        }
      }

      &__img {
        width: 100%;
        aspect-ratio: 4/5;
        transition: 0.3s filter ease-in-out;
        position: relative;
        border: 1px solid rgb(209, 209, 209);

        img {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      &__name {
        font-weight: bold;
      }
      &__info {
        display: flex;
        flex-direction: column;
        gap: 0.5em;
        flex-grow: 1;
        // flex-shrink: 0;
        // height: 100%;
        p {
          margin-top: auto;
        }
        // padding: 1em;
        @media (max-width: 1050px) {
          padding: 0.5em;
        }
      }
      &__btn {
        display: flex;
        position: absolute;
        gap: 1em;
        right: 1em;
        top: 1em;
        z-index: 10;

        @media (max-width: 740px) {
          gap: 0.5em;
          right: 0.5em;
          top: 0.5em;
        }

        svg path {
          fill: rgb(218, 218, 218);
        }
      }
    }
  }
}
