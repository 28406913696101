.banner {
  display: flex;
  width: 100%;
  max-height: 40rem;
  min-height: 30rem;
  padding-bottom: 23%;
  // height: 100%;
  // aspect-ratio: 5/2;
  position: relative;

  .wrapper {
    position: absolute;
    height: 100%;
    width: 100%;
    max-width: 2000px;
    display: flex;
    justify-content: center;
    background-color: rgb(161, 76, 76);
    transform: translateX(-50%);
    left: 50%;
  }

  &__image {
    position: absolute;
    width: 85%;
    height: 85%;
    opacity: 0;
    right: 0;
    top: 0;
    pointer-events: none;
    user-select: none;
    display: flex;
    z-index: 1;

    &--active {
      opacity: 1;
    }

    &__box {
      position: relative;
    }

    img {
      height: 100%;
      object-fit: cover;
    }

    .banner__image__box {
      width: 100%;
      img {
        width: 100%;
      }
    }

    @media (max-width: 1450px) {
      width: 100%;
    }
  }

  &__content {
    position: absolute;
    min-height: 20rem;
    justify-content: space-between;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 4em;
    height: 100%;
    z-index: 2;
  }

  &__text {
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    h2,
    p {
      font-family: "Lato", sans-serif;
    }

    p {
      font-size: 1.2em;
      color: #dedede;
    }

    h2 {
      font-size: 3em;
      font-weight: bold;
      position: absolute;
      left: 8%;
      top: 50%;
      transform: translateY(-50%);
      max-width: 18rem;
      text-shadow: 2px 2px 2px black;
    }

    &__desc {
      position: absolute;
      display: flex;
      bottom: 0;
      right: 8%;
      gap: 1rem;
      align-items: center;
      left: 8%;
      // max-width: rem;
      height: 15%;
    }

    @media (max-width: 1450px) {
      position: relative;
      height: 100%;
      justify-content: center;
      align-items: center;

      h2,
      p {
        text-align: center;
        position: static;
        width: 100%;
      }

      p {
        color: #fff;
        text-shadow: 1px 1px 1px black;
        max-width: 28rem;
      }

      .banner__text__desc {
        height: auto;
        position: static;
      }
    }

    &__color {
      color: #fff;
      text-shadow: 1px 1px 3px black;
    }

    &--short {
      max-width: 30rem;
    }

    // &__link {
    //   color: #202653;
    //   position: relative;
    //   flex-shrink: 0;
    //   padding: 0.5em 0;
    //   width: 8em;
    //   display: flex;
    //   justify-content: center;
    //   background-color: rgb(60, 121, 227);
    //   color: #fff;

    //   &:hover {
    //     color: #fff;
    //     background-color: #202653;
    //   }
    // }
  }

  &__transport {
    display: flex;
    height: 15%;
    align-items: center;
    margin: 0 auto;
    justify-content: end;
    position: absolute;
    right: 5%;
    left: 5%;
    bottom: 0%;
    gap: 2em;
  }

  &__dots {
    display: flex;
    position: relative;
    z-index: 2;
    width: 20rem;
    align-items: center;

    &__num {
      color: #fff;
      padding: 0 0.5rem;
      font-size: 0.75rem;
      font-family: sans-serif;
    }

    &__item {
      cursor: pointer;
      flex: 1;
      height: 0.25rem;
      background-color: #27272774;

      &--active,
      &:hover {
        background-color: rgb(243, 243, 243);
      }
    }
  }

  &__arrow {
    display: flex;
    gap: 2em;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;

    svg {
      width: 1em;
      height: 1em;
      // stroke: rgba(0, 0, 0, 0.26);
      stroke: rgb(225, 225, 225);
      path {
        stroke-width: 3;
        // fill: #fff;
      }
    }

    button {
      cursor: pointer;

      &:nth-of-type(2) {
        transform: rotateY(180deg);
      }
    }
    button:hover {
      svg path {
        // fill: #2d3661;
        stroke: rgb(0, 0, 0);
      }
    }
  }
}

.progressbar {
  width: 100%;
  height: 0.25em;
  max-width: 2000px;
  margin: 0 auto;
  overflow: hidden;

  &__cover {
    width: 100%;
    height: 100%;
    background-color: rgb(224, 224, 224);
    position: relative;

    &__bar {
      width: 100%;
      height: 100%;
      background-color: rgb(18, 18, 18);
      position: absolute;
      left: -100%;
    }
  }

  &--active {
    animation: 8s leftbar linear;
  }
}

@keyframes leftbar {
  100% {
    left: 0%;
  }
}
