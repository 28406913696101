.purchase--loading {
  min-height: 100vh;
}
.purchase .container {
  width: 100%;
  padding: 0.5em;
  gap: 1em;
  display: flex;
}
@media (max-width: 900px) {
  .purchase .container {
    flex-direction: column;
  }
}

.purchase__lists {
  display: flex;
  flex-direction: column;
  max-width: 50rem;
  width: 100%;
  gap: 0.5em;
  overflow-y: auto;
  max-height: 100vh;
}
.purchase__lists.disabled {
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  filter: brightness(70%);
}
.purchase__lists > label {
  display: flex;
  gap: 1em;
  width: 100%;
}
.purchase__lists > label:hover .purchase__lists__checkbox::after {
  background-color: rgb(19, 81, 206);
}
.purchase__lists .display-none {
  display: none;
}
.purchase__lists__checkbox {
  width: 0.5rem;
  height: 0.5rem;
  margin: auto 0;
  border: 1px solid var(--clr-line);
  border-radius: 50%;
  flex-shrink: 0;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 1000px) {
  .purchase__lists__checkbox {
    display: none;
  }
}
.purchase__lists__checkbox::after {
  width: 100%;
  height: 100%;
  content: "";
  border-radius: 50%;
}
.purchase__lists__checkbox--active::after {
  background-color: rgb(98, 168, 226);
}

.purchase__card {
  display: flex;
  gap: 1em;
  width: 100%;
  align-items: center;
  cursor: pointer;
  background-color: #f8f8f8;
  position: relative;
  border: 1px solid rgb(211, 214, 226);
  padding-right: 1em;
}
.purchase__card--active {
  background-color: #ffffff;
}
.purchase__card__img {
  flex-shrink: 0;
  width: 6em;
  aspect-ratio: 7/10;
}
.purchase__card__img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.purchase__card__text {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.2em;
}
.purchase__card__text__model {
  font-size: 0.8em;
}
@media (max-width: 400px) {
  .purchase__card__text__model {
    display: none;
  }
}
.purchase__card__btn-box {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  align-items: center;
  max-width: 8rem;
  flex-shrink: 0;
}
.purchase__card__btn-box > label {
  display: flex;
  align-items: center;
  gap: 0.5em;
  font-size: 0.8em;
  height: 2rem;
}
.purchase__card__btn-box > label input {
  font-size: initial;
  width: 2em;
  text-align: center;
  border: 1px solid var(--clr-line);
  outline: none;
}
.purchase__card__btn-box > label input:disabled {
  background-color: rgb(185, 185, 185);
}
.purchase__card__btn-box > label input:disabled:hover {
  cursor: not-allowed;
}
.purchase__card__btn-box > label input:focus {
  background-color: var(--clr-focus);
}
.purchase__card__btn {
  width: 100%;
  padding: 0.5em 1em;
  border: 1px solid var(--clr-line);
  border-radius: 2px;
  font-size: 0.8em;
  height: 2rem;
}
.purchase__card__btn--active {
  background-color: #95c4eb;
}
.purchase__card__btn--active:hover {
  background-color: #559ee2 !important;
}
.purchase__card__btn:hover {
  background-color: #b6b6b6;
}
.purchase__card__delete {
  flex-shrink: 0;
  height: 100%;
  width: 2em;
  background-color: rgb(214, 214, 214);
  display: flex;
  justify-content: center;
  align-items: center;
}
.purchase__card__delete svg {
  width: 1em;
  height: 1em;
}
.purchase__card__delete svg rect {
  fill: rgb(34, 34, 34);
}
.purchase__card__delete:hover {
  background-color: rgb(180, 180, 180);
}

.purchase__form {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1em;
  background-color: #fff;
  padding: 1em;
  margin-bottom: auto;
}
.purchase__form__progress {
  display: flex;
  gap: 1em;
  position: relative;
}
.purchase__form__progress__label {
  flex: 1 1 100%;
  padding: 0.5em;
  text-align: center;
  z-index: 2;
  background-color: rgb(219, 219, 219);
  border-radius: 4px;
  transition: 0.3s background-color;
  font-size: 0.9em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.purchase__form__progress__label.active {
  background-color: rgb(144, 183, 235);
}
.purchase__form__progress__line {
  width: 100%;
  height: 2px;
  background-color: rgb(219, 219, 219);
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.purchase__form__content {
  overflow: hidden;
}
.purchase__form__step {
  display: flex;
  width: 100%;
  position: relative;
  left: 0;
  transition: 0.3s left ease-in-out;
}
.purchase__form__step__page {
  flex-shrink: 0;
  width: 100%;
  gap: 1em;
  display: flex;
  flex-direction: column;
  padding: 1em;
}
.purchase__form__step__page h2 {
  font-weight: bold;
  font-size: 1.2em;
  text-align: center;
}
.purchase__form__invoice {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.purchase__form__invoice__box {
  display: flex;
  flex-direction: column;
  height: 8rem;
  overflow-y: auto;
}
.purchase__form__invoice__item {
  border: 1px solid var(--clr-line);
  display: grid;
  grid-template-columns: repeat(1, 1fr 2em 6em);
}
.purchase__form__invoice__item:not(:nth-of-type(1)) {
  border-top: none;
}
.purchase__form__invoice__item > p {
  padding: 0.2em;
  height: 100%;
  display: flex;
  align-items: center;
}
.purchase__form__invoice__item > p:nth-of-type(1) {
  flex: 0 70%;
}
.purchase__form__invoice__item > p:nth-of-type(2) {
  border-left: 1px solid var(--clr-line);
  justify-content: center;
}
.purchase__form__invoice__item > p:nth-of-type(3) {
  border-left: 1px solid var(--clr-line);
  justify-content: right;
}
.purchase__form__total span {
  display: inline-block;
  width: 8rem;
}
.purchase__form__next {
  display: flex;
  gap: 0.5em;
  justify-content: space-between;
}
.purchase__form__next button {
  padding: 0.5em 2em;
  background-color: rgb(144, 183, 235);
  border-radius: 4px;
  color: black;
  font-size: 0.9em;
}
.purchase__form__next button:hover {
  filter: opacity(0.6);
}
.purchase__form__next button:disabled {
  filter: opacity(0.6);
  cursor: not-allowed;
}
.purchase__form__user__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto 0;
  gap: 1em;
  max-width: 16rem;
  margin: auto;
  text-align: center;
}
.purchase__form__user__content a {
  padding: 0.5em 1em;
  border-radius: 4px;
  background-color: var(--clr-dark);
  color: #fff;
}
.purchase__form__user__content a:hover {
  background-color: #3b3b3b;
}/*# sourceMappingURL=purchase.css.map */