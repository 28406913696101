.shop__aside {
  width: 20rem;
  display: flex;
  flex-direction: column;
  gap: 1em;
}
@media (max-width: 1050px) {
  .shop__aside {
    width: 100%;
  }
}
.shop__aside div,
.shop__aside p,
.shop__aside input,
.shop__aside button {
  font-family: "Roboto", sans-serif;
}
.shop__aside__header p {
  color: #443d3d;
  font-weight: bold;
}
.shop__aside__container {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.shop__aside__search input {
  width: 100%;
  padding: 0.5em;
  border: 1px solid var(--clr-line);
  outline: none;
}
.shop__aside__search input:focus {
  background-color: var(--clr-focus);
}
.shop__aside__color__btn-box {
  display: flex;
  flex-wrap: wrap;
  padding: 0.5em 0;
  gap: 0.3em;
}
.shop__aside__color__item {
  display: flex;
  padding: 0.3rem;
  border: 1px solid var(--clr-line);
  font-size: 0.9em;
  text-transform: uppercase;
}
.shop__aside__color__item--active {
  background-color: rgb(138, 138, 190);
  color: #fff;
}
.shop__aside__trigger {
  display: flex;
  position: relative;
  border: 1px solid var(--clr-line);
  background-color: #fff;
}
.shop__aside__trigger--active, .shop__aside__trigger:hover {
  background-color: var(--clr-focus);
}
.shop__aside__trigger button {
  padding: 0.5rem;
  width: 100%;
  text-align: left;
  font-size: 0.9em;
}
.shop__aside__trigger svg {
  position: absolute;
  right: 0.25rem;
  top: 50%;
  transform: translateY(-50%);
  width: 0.8rem;
}
.shop__aside__trigger svg rect {
  fill: #666666;
}
.shop__aside__price__input-box {
  display: flex;
  padding: 0.5em 0;
  gap: 0.3rem;
  align-items: center;
}
.shop__aside__price__input-box input {
  text-align: center;
  width: 5em;
  padding: 0.2em;
  border: 1px solid var(--clr-line);
  outline: none;
}
.shop__aside__price__input-box input:focus {
  background-color: var(--clr-focus);
}
.shop__aside__filters {
  display: flex;
  gap: 0.3rem;
  flex-wrap: wrap;
  padding-bottom: 1rem;
}
.shop__aside__filters__btn {
  padding: 0.3em 0.6em;
  background-color: rgb(73, 73, 78);
  color: #fff;
  text-transform: uppercase;
  font-size: 0.9em;
}
.shop__aside__filters__btn:hover {
  background-color: rgb(105, 101, 101);
}/*# sourceMappingURL=shopaside.css.map */