.shop__aside {
  width: 20rem;
  display: flex;
  flex-direction: column;
  gap: 1em;
  @media (max-width: 1050px) {
    width: 100%;
  }

  div,
  p,
  input,
  button {
    font-family: "Roboto", sans-serif;
  }

  &__header p {
    color: #443d3d;
    font-weight: bold;
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 1em;
  }

  &__search {
    input {
      width: 100%;
      padding: 0.5em;
      border: 1px solid var(--clr-line);
      outline: none;
      &:focus {
        background-color: var(--clr-focus);
      }
    }
  }

  &__color {
    &__btn-box {
      display: flex;
      flex-wrap: wrap;
      padding: 0.5em 0;
      gap: 0.3em;
    }

    &__item {
      display: flex;
      padding: 0.3rem;
      border: 1px solid var(--clr-line);
      font-size: 0.9em;
      text-transform: uppercase;
      &--active {
        background-color: rgb(138, 138, 190);
        color: #fff;
      }
    }
  }

  &__trigger {
    display: flex;
    position: relative;
    border: 1px solid var(--clr-line);
    background-color: #fff;
    &--active,
    &:hover {
      background-color: var(--clr-focus);
    }

    button {
      padding: 0.5rem;
      width: 100%;
      text-align: left;
      font-size: 0.9em;
    }

    svg {
      position: absolute;
      right: 0.25rem;
      top: 50%;
      transform: translateY(-50%);
      width: 0.8rem;
      rect {
        fill: #666666;
      }
    }
  }

  &__price {
    &__input-box {
      display: flex;
      padding: 0.5em 0;
      gap: 0.3rem;
      align-items: center;
      input {
        text-align: center;
        width: 5em;
        padding: 0.2em;

        border: 1px solid var(--clr-line);
        outline: none;
        &:focus {
          background-color: var(--clr-focus);
        }
      }
    }
  }

  &__filters {
    display: flex;
    gap: 0.3rem;
    flex-wrap: wrap;
    padding-bottom: 1rem;

    &__btn {
      padding: 0.3em 0.6em;
      background-color: rgb(73, 73, 78);
      color: #fff;
      text-transform: uppercase;
      font-size: 0.9em;

      &:hover {
        background-color: rgb(105, 101, 101);
      }
    }
  }
}
