.home {
  display: flex;
  flex-direction: column;
  gap: 3em;
}
.home h2 span {
  color: rgb(216, 39, 113);
}
@media (max-width: 740px) {
  .home {
    gap: 1em;
  }
}
.home__main-section .wrapper {
  padding: 0em 0.5em;
}
.home__main-section .container {
  gap: 0.5em;
}
.home__main-section h2 {
  font-size: 1.5em;
  font-weight: bold;
  font-family: sans-serif;
}
.home__section .container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}
.home__section__box {
  margin: 0 auto;
  display: grid;
  gap: 1em;
  grid-template-columns: repeat(4, minmax(120px, 1fr));
}
@media (max-width: 600px) {
  .home__section__box {
    grid-template-columns: repeat(2, minmax(120px, 1fr));
  }
}
.home__section__item {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.home__section__item:hover p:nth-of-type(1) {
  text-decoration: underline;
}
.home__section__img {
  display: flex;
}
.home__section__img img {
  width: 100%;
  aspect-ratio: 3/4;
  -o-object-fit: cover;
     object-fit: cover;
}
.home__section__text {
  padding: 0.5em 0;
  border-top: none;
  gap: 0.5em;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.home__section__text p:nth-of-type(3) {
  margin-top: auto;
  color: #fff;
  text-transform: uppercase;
  font-size: 0.8em;
  padding: 0.25em 0.5em;
  background-color: rgb(235, 145, 145);
  width: -moz-max-content;
  width: max-content;
  border-radius: 4px;
}
.home__section__text p:nth-of-type(1) {
  font-weight: bold;
}
.home__section__text p {
  color: var(--clr-dark);
  font-size: 1em;
}
.home__section__text__sale {
  display: flex;
  align-items: center;
  gap: 0.5em;
}
.home__brand-new .wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  position: relative;
  padding: 0;
}
.home__brand-new h2 {
  left: 0.5em;
  top: 0em;
  position: absolute;
  z-index: 9;
  font-size: 3em;
  font-weight: 900 !important;
  color: rgb(233, 233, 233);
  text-shadow: 1px 1px 1px black;
}
.home__brand-new .container {
  display: flex;
  position: relative;
}
@media (max-width: 740px) {
  .home__brand-new .container {
    flex-direction: column;
  }
}
.home__brand-new .container:hover p:nth-of-type(1) {
  text-decoration: underline;
}
.home__brand-new__img {
  aspect-ratio: 11/4;
  width: 100%;
  max-width: 1400px;
}
.home__brand-new__img img {
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: 50%;
     object-position: 50%;
  width: 100%;
  height: 100%;
}
@media (max-width: 460px) {
  .home__brand-new__img {
    aspect-ratio: 11/6;
  }
  .home__brand-new__img img {
    -o-object-position: 80%;
       object-position: 80%;
  }
}
.home__brand-new__text {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  justify-content: center;
  position: absolute;
  background-color: var(--clr-light);
  align-items: center;
  text-align: center;
  padding: 1em 4em;
  left: 1em;
  bottom: 1em;
  box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.443);
}
@media (max-width: 740px) {
  .home__brand-new__text {
    position: static;
    box-shadow: none;
    padding: 0;
    text-align: left;
  }
}
.home__brand-new__text p:nth-of-type(1) {
  font-size: 1.2em;
}
.home__brand-new__text .role-link {
  width: -moz-max-content;
  width: max-content;
  border: 1px solid var(--clr-line);
  padding: 0.5em 1.5em;
}

.remove-cookie .container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1em;
  padding: 1em;
}
.remove-cookie__text {
  display: flex;
  flex-direction: column;
  gap: 1em;
  max-width: 60rem;
}
.remove-cookie__text h3 {
  font-weight: bold;
  margin: 0 auto;
}
.remove-cookie__text__btn-box {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}
.remove-cookie button {
  padding: 0.5em 1em;
  background-color: rgb(194, 39, 39);
  color: #fff;
  font-size: 0.9em;
  margin: 0 auto;
}
.remove-cookie button:hover {
  background-color: rgb(185, 85, 85);
}
.remove-cookie h3,
.remove-cookie p,
.remove-cookie button {
  font-family: "Roboto", sans-serif;
}/*# sourceMappingURL=home.css.map */