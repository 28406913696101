.no-data {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  .wrapper {
    height: 100%;
  }
  .container {
    display: flex;
    width: max-content;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    margin: 0 auto;
    height: 100%;
  }
  h3 {
    font-size: 2em;
    font-weight: bold;
    color: rgb(70, 63, 63);
  }

  &--fav {
    min-height: 60vh;
    display: flex;
    align-items: center;

    border: none;
    text-align: center;
    .container {
      max-width: 24rem;
      width: 100%;
      gap: 1em !important;
      span {
        color: rgb(231, 105, 105);
        font-weight: bold;
      }
    }
  }

  a {
    margin: 0 auto;
    border-radius: 4px;
    padding: 1em 2em;
    background-color: var(--clr-dark);
    text-align: center;
    color: var(--clr-mid);
    &:hover {
      background-color: rgb(51, 51, 51);
    }
  }
}
