.product {
  &__path {
    padding: 0 1em;
    padding-top: 1em;
    display: flex;
    gap: 0.5em;
    p,
    span {
      pointer-events: none;
    }
  }
  @media (max-width: 900px) {
    .container {
      flex-direction: column;
    }
    .product__text__shipping {
      max-width: 100%;
    }
    .product__text__purchase {
      max-width: 100%;
    }
  }
  .container {
    display: flex;
  }
  &__imgs {
    display: flex;
    gap: 0.5em;
    max-width: 60rem;
    width: 100%;
    aspect-ratio: 27/28;
    padding: 1em;
    @media (max-width: 600px) {
      padding: 0.5rem;
    }

    &__front {
      position: relative;
      display: flex;
      flex: 1 1 100%;

      &__item {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        overflow: hidden;
        cursor: crosshair;
        pointer-events: none;

        &__user-select-prevention {
          width: 100%;
          height: 100%;
          touch-action: none;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          position: absolute;
          user-select: none;
          pointer-events: none;
        }
        &--active {
          opacity: 1;
          pointer-events: auto;
        }
      }
    }

    &__select {
      width: 22%;
      min-width: 6rem;
      height: 100%;
      display: flex;
      gap: 0.5em;
      flex-direction: column;
      overflow-y: auto;

      @media (max-width: 500px) {
        min-width: 4rem;
      }

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgb(38, 40, 48);
        border-radius: 20px;
      }

      &__item {
        flex-shrink: 0;
        width: 100%;
        aspect-ratio: 6/8;
        overflow: hidden;
        cursor: pointer;
        filter: brightness(40%);
        transition: filter 0.2s;

        &--active,
        &:hover {
          filter: brightness(100%);
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  &__text {
    width: 100%;
    padding: 1em;
    display: flex;
    flex-direction: column;
    gap: 1em;
    @media (max-width: 600px) {
      padding: 0.5rem;
    }

    &__info {
      display: flex;
      flex-direction: column;

      h1 {
        font-size: 1.4em;
        font-weight: bold;
      }
    }

    &__trait {
      display: flex;
      flex-direction: column;
      gap: 0.5em;
      span {
        display: inline-block;
        text-transform: uppercase;
        padding: 0.4em 0.8em;
        background-color: rgb(219, 219, 219);
        border-radius: 4px;
        font-size: 0.8em;
      }
    }

    &__sale {
      .hasSale {
        color: red;
      }
    }

    &__size {
      position: relative;
      width: 100%;
      max-width: 20rem;
      border-radius: 4px;
      z-index: 9;

      &__cover {
        border: 1px solid var(--clr-line);
        width: 100%;
        height: 100%;
        display: flex;
        padding: 0.5em;
        cursor: pointer;
        background-color: var(--clr-light);

        &:hover {
          background-color: var(--clr-focus);
        }
      }

      &__item {
        position: absolute;
        width: 100%;
        left: 0;
        top: 100%;
        border: 1px solid var(--clr-line);
        border-top: none;
        display: none;

        &--active {
          display: block;
        }

        div {
          padding: 0.5em;
          display: flex;
          cursor: pointer;
          background-color: var(--clr-light);
          z-index: 9;

          &:hover,
          &.product__text__size__item__active {
            background-color: var(--clr-focus);
          }
        }
      }
    }

    &__shipping {
      display: flex;
      flex-direction: column;
      gap: 0.5em;
      max-width: 40rem;

      label {
        padding: 0.5em 1em;
        background-color: rgb(233, 233, 233);
        display: flex;
        flex-wrap: wrap;
        gap: 0.5em;
        border-radius: 8px;
        cursor: pointer;
        align-items: center;
        span {
          width: 100%;
          font-size: 0.9em;
          color: #6b6b6b;
        }

        &:hover input::after {
          background-color: rgb(117, 117, 117);
        }
      }

      input {
        appearance: none;
        width: 1rem;
        height: 1rem;
        background-color: rgb(189, 189, 189);
        border-radius: 50%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &::after {
          content: "";
          position: absolute;
          width: 0.5rem;
          height: 0.5rem;
          background-color: transparent;
          border-radius: 50%;
        }
      }

      input[type="radio"]:checked {
        &::after {
          background-color: rgb(35, 38, 44);
        }
      }
    }

    &__purchase {
      display: flex;
      flex-direction: column;
      gap: 1em;
      max-width: 40rem;

      &__link {
        background-color: rgb(54, 54, 54);
        color: #fff;
        padding: 1em 2em;
        text-align: center;
        border-radius: 4px;

        &:hover {
          background-color: rgb(73, 73, 73);
        }
      }

      &__btn {
        display: flex;
        align-items: center;
        gap: 1em;
        padding: 1em 2em;
        justify-content: center;
        cursor: pointer;
        border-radius: 4px;
        border: 1px solid var(--clr-line);

        &:hover:nth-of-type(1) {
          background-color: rgb(228, 236, 226);
          svg path {
            stroke: #161616;
            stroke-width: 2px;
            fill: rgb(89, 212, 73);
          }
        }

        &:hover:nth-of-type(2) {
          background-color: rgb(240, 226, 226);
          svg path {
            stroke: #161616;
            stroke-width: 2px;
            fill: rgb(221, 82, 82);
          }
        }
      }
    }

    &__warning {
      font-size: 0.8em;
      color: rgb(245, 78, 78);
      display: none;
      &--active {
        display: block;
      }
    }
  }

  @media (max-width: 900px) {
    flex-direction: column;
  }
}
