.card {
  min-height: 60vh;
  width: 100%;
}
.card__content {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, minmax(260px, 1fr));
  gap: 0.5em;
  justify-items: center;
  row-gap: 2em;
}
@media (max-width: 1350px) {
  .card__content {
    grid-template-columns: repeat(3, minmax(120px, 1fr));
  }
}
@media (max-width: 500px) {
  .card__content {
    grid-template-columns: repeat(2, minmax(120px, 1fr));
  }
}
.card__content__item {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.card__content__item:hover h3 {
  text-decoration: underline;
}
.card__content__item__img {
  width: 100%;
  aspect-ratio: 4/5;
  transition: 0.3s filter ease-in-out;
  position: relative;
  border: 1px solid rgb(209, 209, 209);
}
.card__content__item__img img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.card__content__item__name {
  font-weight: bold;
}
.card__content__item__info {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  flex-grow: 1;
}
.card__content__item__info p {
  margin-top: auto;
}
@media (max-width: 1050px) {
  .card__content__item__info {
    padding: 0.5em;
  }
}
.card__content__item__btn {
  display: flex;
  position: absolute;
  gap: 1em;
  right: 1em;
  top: 1em;
  z-index: 10;
}
@media (max-width: 740px) {
  .card__content__item__btn {
    gap: 0.5em;
    right: 0.5em;
    top: 0.5em;
  }
}
.card__content__item__btn svg path {
  fill: rgb(218, 218, 218);
}/*# sourceMappingURL=card.css.map */