.home {
  display: flex;
  flex-direction: column;
  gap: 3em;

  h2 {
    span {
      color: rgb(216, 39, 113);
    }
  }

  @media (max-width: 740px) {
    gap: 1em;
  }

  &__main-section {
    .wrapper {
      padding: 0em 0.5em;
    }
    .container {
      gap: 0.5em;
    }
    h2 {
      font-size: 1.5em;
      font-weight: bold;
      font-family: sans-serif;
    }
  }

  // --------------- best seller starts from here
  &__section {
    .container {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
    }
    &__box {
      margin: 0 auto;
      display: grid;
      gap: 1em;
      grid-template-columns: repeat(4, minmax(120px, 1fr));
      @media (max-width: 600px) {
        grid-template-columns: repeat(2, minmax(120px, 1fr));
      }
    }
    &__item {
      display: flex;
      flex-direction: column;
      cursor: pointer;

      &:hover {
        p:nth-of-type(1) {
          text-decoration: underline;
        }
        // outline: 1px solid rgb(161, 161, 161);
      }
    }
    &__img {
      display: flex;

      img {
        width: 100%;
        aspect-ratio: 3/4;
        object-fit: cover;
      }
    }
    &__text {
      padding: 0.5em 0;
      border-top: none;
      gap: 0.5em;
      display: flex;
      flex-direction: column;
      height: 100%;

      p:nth-of-type(3) {
        margin-top: auto;
        color: #fff;
        text-transform: uppercase;
        font-size: 0.8em;
        padding: 0.25em 0.5em;
        background-color: rgb(235, 145, 145);
        width: max-content;
        border-radius: 4px;
      }

      p:nth-of-type(1) {
        font-weight: bold;
      }

      p {
        color: var(--clr-dark);
        font-size: 1em;
      }

      &__sale {
        display: flex;
        align-items: center;
        gap: 0.5em;
      }
    }
  }

  // --------------- new cloth starts from here
  &__brand-new {
    .wrapper {
      display: flex;
      flex-direction: column;
      gap: 0.5em;
      position: relative;
      padding: 0;
    }

    h2 {
      left: 0.5em;
      top: 0em;
      position: absolute;
      z-index: 9;
      font-size: 3em;
      font-weight: 900 !important;
      color: rgb(233, 233, 233);
      text-shadow: 1px 1px 1px black;
    }

    .container {
      display: flex;
      position: relative;

      @media (max-width: 740px) {
        flex-direction: column;
      }

      &:hover {
        p:nth-of-type(1) {
          text-decoration: underline;
        }
        // .role-link {
        //   border: 1px solid black;
        //   color: black;
        // }
      }
    }
    &__img {
      aspect-ratio: 11/4;
      width: 100%;
      max-width: 1400px;

      img {
        object-fit: cover;
        object-position: 50%;
        width: 100%;
        height: 100%;
      }

      @media (max-width: 460px) {
        aspect-ratio: 11/6;
        img {
          object-position: 80%;
        }
      }
    }
    &__text {
      display: flex;
      flex-direction: column;
      gap: 0.5em;
      justify-content: center;
      position: absolute;
      background-color: var(--clr-light);
      align-items: center;
      text-align: center;
      padding: 1em 4em;
      left: 1em;
      bottom: 1em;
      box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.443);

      @media (max-width: 740px) {
        position: static;
        box-shadow: none;
        padding: 0;
        text-align: left;
      }

      p:nth-of-type(1) {
        font-size: 1.2em;
      }
      .role-link {
        width: max-content;
        border: 1px solid var(--clr-line);
        padding: 0.5em 1.5em;
      }
    }
  }
}

.remove-cookie {
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1em;
    padding: 1em;
  }

  &__text {
    display: flex;
    flex-direction: column;
    gap: 1em;
    max-width: 60rem;

    h3 {
      font-weight: bold;
      margin: 0 auto;
    }

    &__btn-box {
      display: flex;
      flex-direction: column;
      gap: 0.5em;
    }
  }

  button {
    padding: 0.5em 1em;
    background-color: rgb(194, 39, 39);
    color: #fff;
    font-size: 0.9em;
    margin: 0 auto;
    &:hover {
      background-color: rgb(185, 85, 85);
    }
  }

  h3,
  p,
  button {
    font-family: "Roboto", sans-serif;
  }
}
