.product-loading {
  display: flex;
  width: 100%;
  flex-direction: column;

  &__container {
    display: flex;
    gap: 1em;
    @media (max-width: 900px) {
      flex-direction: column;
    }
  }
}

.product-loading__route {
  padding: 1em;
  display: flex;
  gap: 0.5em;
  &__path {
    width: 4em;
    height: 1em;
    background-color: var(--clr-loading-light);
    position: relative;
    overflow: hidden;
    &::after {
      animation: var(--ani-loading);
      background-color: var(--clr-loading-move);
      content: "";
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.product-loading__imgs {
  display: flex;
  gap: 0.5em;
  max-width: 60rem;
  width: 100%;
  aspect-ratio: 27/28;
  padding: 1em;
  background-color: var(--clr-loading-light);
  @media (max-width: 600px) {
    padding: 0.5rem;
  }

  &__lg {
    flex: 1 1 100%;
    height: 100%;
    background-color: var(--clr-loading-dark);
    position: relative;
    overflow: hidden;
    &::after {
      animation: var(--ani-loading);
      background-color: var(--clr-loading-move);
      content: "";
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
  &__box {
    width: 22%;
    min-width: 6rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    &__sm {
      width: 100%;
      aspect-ratio: 6/8;
      background-color: var(--clr-loading-dark);
      position: relative;
      overflow: hidden;
      &::after {
        animation: var(--ani-loading);
        background-color: var(--clr-loading-move);
        content: "";
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.product-loading__texts {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1em;
  @media (max-width: 600px) {
    padding: 0.5rem;
  }

  &__box {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    padding: 0.5em;
    background-color: var(--clr-loading-light);

    &__paragraph {
      width: 100%;
      max-width: 20rem;
      height: 1em;
      background-color: var(--clr-loading-dark);
      position: relative;
      overflow: hidden;
      &::after {
        animation: var(--ani-loading);
        background-color: var(--clr-loading-move);
        content: "";
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }
    &__btn {
      max-width: 40rem;
      width: 100%;
      height: 4em;
      background-color: var(--clr-loading-dark);
      position: relative;
      overflow: hidden;
      &::after {
        animation: var(--ani-loading);
        background-color: var(--clr-loading-move);
        content: "";
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}
