.searchbar {
  position: relative;
  height: 100%;
  display: flex;
  width: 100%;
  max-width: 40rem;
  z-index: 999;
  &__input {
    width: 100%;
    height: 100;
    padding: 0.5em;
    border: 1px solid var(--clr-line);
    outline: none;
    &:focus {
      background-color: var(--clr-focus);
    }
  }
  &__lists {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    background-color: var(--clr-white);
    border: 1px solid var(--clr-line);
    border-top: none;
    &__item {
      cursor: pointer;
      padding: 0.5em;

      &:hover {
        background-color: var(--clr-focus);
      }
    }
  }

  &__icon {
    flex-shrink: 0;
    cursor: pointer;
    border: 1px solid var(--clr-line);
    border-left: none;
    padding: 0 0.5em;
    align-items: center;
    display: flex;
    background-color: #fff;

    svg {
      width: 1.5em;
      height: 1.5em;
      path {
        fill: rgb(100, 100, 100);
      }
    }
    &:hover {
      svg {
        path {
          fill: rgb(29, 29, 29);
        }
      }
    }
  }
}
