@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font: inherit;
  /* font-family: "Roboto", sans-serif; */
  /* font-family: "Lato", sans-serif; */
  font-family: "Merriweather", serif;
}

:root {
  --clr-white: #f8f8f8;
  --clr-light: #f1f1f1;
  --clr-mid: rgb(216, 215, 215);
  --clr-dark: black;
  --clr-primary: blue;
  --clr-secondary: red;
  --clr-dark-fnt: rgb(80, 80, 80);
  --clr-light-fnt: #fff;
  --clr-line: rgb(172, 172, 172);
  --clr-focus: rgb(228, 228, 250);

  /* loading component */
  --clr-loading-light: rgb(219, 219, 219);
  --clr-loading-dark: rgb(185, 185, 185);
  --clr-loading-move: rgb(146, 146, 146);
  --ani-loading: ani-loading 2s ease-in-out infinite;

  --clr-line-light: #e2e2e2;
}

img,
picture,
svg,
video {
  display: block;
  max-width: 100%;
}

img {
  width: 100%;
  height: 100%;
}

svg {
  pointer-events: none;
}
a {
  color: var(--clr-dark-fnt);
  text-decoration: none;
}
li {
  list-style: none;
}
button {
  border: none;
  cursor: pointer;
  background-color: transparent;
  color: var(--clr-dark-fnt);
}
body {
  min-height: 100vh;
  background-color: var(--clr-light);
}

.wrapper {
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
}

/* sale price style */
/* <span cross>{price} <span saleoff>{sale} {getCalculator} */
.sale {
  display: flex;
  gap: 0.5em;
  align-items: center;
}
.sale span {
  color: #5c5c5c;
  font-size: 0.8em;
  &:is(.cross) {
    position: relative;
    &::after {
      position: absolute;
      content: "";
      width: 100%;
      height: 1px;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background-color: red;
    }
  }
  &:is(.saleoff) {
    background-color: rgb(224, 224, 240);
    padding: 0.2em;
    border-radius: 4px;
  }
}

.btn-svg svg path {
  stroke: #505050;
  stroke-width: 2px;
}

.btn-svg--like:hover svg path,
.btn-svg--like--active svg path {
  stroke: #161616;
  stroke-width: 2px;
  fill: rgb(221, 82, 82) !important;
}

.btn-svg--basket:hover svg path,
.btn-svg--basket--active svg path {
  stroke: #161616;
  stroke-width: 2px;
  fill: rgb(89, 212, 73) !important;
}

.img-ready {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 1;
  transition: 0.3s opacity;
  z-index: 9;
  background-color: var(--clr-loading-light);
  pointer-events: none;
  user-select: none;
}

.img-ready--active {
  opacity: 0;
}

.break {
  width: 100%;
  height: 0.5rem;
  background-color: rgb(223, 223, 223);
}
@media (min-width: 740px) {
  .break {
    display: none;
  }
}

@media (max-width: 1350px) {
  body {
    font-size: 0.9rem;
  }
}

@media (max-width: 740px) {
  body {
    font-size: 0.8rem;
  }
}

@keyframes ani-loading {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
