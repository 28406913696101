.loading {
  display: flex;
  width: 100%;
}
.loading .container {
  display: flex;
}

.container:has(.loading) {
  padding: 0;
}

.loading__aside {
  display: flex;
  width: 17rem;
  flex-direction: column;
  gap: 1em;
}
@media (max-width: 1050px) {
  .loading__aside {
    width: 100%;
  }
}
.loading__aside__box {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--clr-loading-light);
  gap: 1em;
  padding: 1em;
}
.loading__aside__box__text {
  width: 100%;
  height: 1em;
  background-color: var(--clr-loading-dark);
  position: relative;
  overflow: hidden;
}
.loading__aside__box__text::after {
  animation: var(--ani-loading);
  background-color: var(--clr-loading-move);
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.loading__main {
  flex: 1 1 60%;
  display: grid;
  grid-template-columns: repeat(4, minmax(260px, 1fr));
  gap: 0.5em;
}
@media (max-width: 1350px) {
  .loading__main {
    grid-template-columns: repeat(3, minmax(260px, 1fr));
  }
}
@media (max-width: 860px) {
  .loading__main {
    grid-template-columns: repeat(3, minmax(220px, 1fr));
  }
}
@media (max-width: 740px) {
  .loading__main {
    grid-template-columns: repeat(2, minmax(180px, 1fr));
    gap: 0.25em;
  }
}
@media (max-width: 380px) {
  .loading__main {
    grid-template-columns: repeat(1, minmax(180px, 1fr));
    gap: 0.25em;
  }
}
.loading__main__box {
  background-color: var(--clr-loading-light);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.loading__main__box__item {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1em;
}
.loading__main__box__img {
  overflow: hidden;
  width: 100%;
  height: 100%;
  aspect-ratio: 4/5;
  background-color: var(--clr-loading-dark);
  position: relative;
}
.loading__main__box__img::after {
  animation: var(--ani-loading);
  background-color: var(--clr-loading-move);
  content: "";
  position: absolute;
  left: -100%;
  top: 0;
  width: 100%;
  height: 100%;
}
.loading__main__box__text {
  width: 100%;
  height: 4em;
  padding: 1em;
  padding-top: 0;
}
.loading__main__box__text > div {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  background-color: var(--clr-loading-dark);
}
.loading__main__box__text > div::after {
  animation: var(--ani-loading);
  background-color: var(--clr-loading-move);
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}
.loading__main__box__btn {
  display: flex;
  position: absolute;
  gap: 1em;
  right: 1em;
  top: 1em;
}
@media (max-width: 740px) {
  .loading__main__box__btn {
    right: 0.5em;
    top: 0.5em;
  }
}
.loading__main__box__svg {
  width: 1.5em;
  height: 1.5em;
  background-color: var(--clr-loading-light);
  border-radius: 4px;
}/*# sourceMappingURL=loadingpage.css.map */