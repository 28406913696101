.product-loading {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.product-loading__container {
  display: flex;
  gap: 1em;
}
@media (max-width: 900px) {
  .product-loading__container {
    flex-direction: column;
  }
}

.product-loading__route {
  padding: 1em;
  display: flex;
  gap: 0.5em;
}
.product-loading__route__path {
  width: 4em;
  height: 1em;
  background-color: var(--clr-loading-light);
  position: relative;
  overflow: hidden;
}
.product-loading__route__path::after {
  animation: var(--ani-loading);
  background-color: var(--clr-loading-move);
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.product-loading__imgs {
  display: flex;
  gap: 0.5em;
  max-width: 60rem;
  width: 100%;
  aspect-ratio: 27/28;
  padding: 1em;
  background-color: var(--clr-loading-light);
}
@media (max-width: 600px) {
  .product-loading__imgs {
    padding: 0.5rem;
  }
}
.product-loading__imgs__lg {
  flex: 1 1 100%;
  height: 100%;
  background-color: var(--clr-loading-dark);
  position: relative;
  overflow: hidden;
}
.product-loading__imgs__lg::after {
  animation: var(--ani-loading);
  background-color: var(--clr-loading-move);
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}
.product-loading__imgs__box {
  width: 22%;
  min-width: 6rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}
.product-loading__imgs__box__sm {
  width: 100%;
  aspect-ratio: 6/8;
  background-color: var(--clr-loading-dark);
  position: relative;
  overflow: hidden;
}
.product-loading__imgs__box__sm::after {
  animation: var(--ani-loading);
  background-color: var(--clr-loading-move);
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.product-loading__texts {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1em;
}
@media (max-width: 600px) {
  .product-loading__texts {
    padding: 0.5rem;
  }
}
.product-loading__texts__box {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  padding: 0.5em;
  background-color: var(--clr-loading-light);
}
.product-loading__texts__box__paragraph {
  width: 100%;
  max-width: 20rem;
  height: 1em;
  background-color: var(--clr-loading-dark);
  position: relative;
  overflow: hidden;
}
.product-loading__texts__box__paragraph::after {
  animation: var(--ani-loading);
  background-color: var(--clr-loading-move);
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}
.product-loading__texts__box__btn {
  max-width: 40rem;
  width: 100%;
  height: 4em;
  background-color: var(--clr-loading-dark);
  position: relative;
  overflow: hidden;
}
.product-loading__texts__box__btn::after {
  animation: var(--ani-loading);
  background-color: var(--clr-loading-move);
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}/*# sourceMappingURL=productSkeletonLoading.css.map */