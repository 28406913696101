.shop {
  .container {
    display: flex;
    gap: 0.5em;
    padding: 1em 0.5em;
    @media (max-width: 1050px) {
      flex-direction: column;
    }
  }
}
