.errorpage .container {
  min-height: 40em;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
}
.errorpage__text {
  display: flex;
  flex-direction: column;
  gap: 3em;
}
.errorpage__text h3 {
  font-weight: bolder;
  font-size: 4em;
}
.errorpage__text h3,
.errorpage__text p {
  color: rgb(53, 47, 47);
}/*# sourceMappingURL=errorpage.css.map */