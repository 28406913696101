header {
  background-color: var(--clr-white);
  border-bottom: 1px solid var(--clr-mid);
  position: relative;
  z-index: 999;
}

nav {
  display: flex;
  padding: 0em 2em;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5em;
}
@media (max-width: 600px) {
  nav {
    padding: 0.5em;
  }
  nav .nav__flex .nav__logo {
    display: none;
  }
  nav .nav__flex .searchbar {
    display: none;
  }
}

.nav__top {
  align-items: center;
  justify-content: space-between;
  gap: 1em;
  display: none;
}
@media (max-width: 600px) {
  .nav__top {
    display: flex;
  }
}

.nav__flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.5em;
}

.nav__content {
  display: flex;
  align-items: center;
  gap: 1.5em;
  padding: 0.5em 0;
  flex-shrink: 0;
}
.nav__content:nth-of-type(1) a:hover,
.nav__content:nth-of-type(1) button:hover,
.nav__content:nth-of-type(1) .active {
  font-weight: bold;
  color: rgb(63, 52, 52);
}
.nav__content svg {
  pointer-events: none;
}

.nav__logo {
  width: 3em;
  height: 3em;
  flex-shrink: 0;
}

.nav__cloth {
  display: flex;
}

.nav__lists {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  border-bottom: 1px solid var(--clr-mid);
  background-color: var(--clr-white);
  display: none;
}
.nav__lists--active {
  display: block;
}
.nav__lists .wrapper {
  padding: 1.5em 2em;
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 4em;
}
@media (max-width: 600px) {
  .nav__lists .wrapper {
    padding: 0.5em 1em;
    gap: 2em;
  }
}
.nav__lists__box {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  width: 100%;
}
.nav__lists__box a:hover {
  text-decoration: underline;
  color: black;
}
.nav__lists__category {
  color: #8a8484;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  pointer-events: none;
}

.nav__cloth__btn--clothes--active {
  text-decoration: underline;
}

.nav__count {
  position: relative;
}
.nav__count__number {
  position: absolute;
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  right: -0.5rem;
  top: -0.5rem;
  border: 2px solid rgb(100, 88, 88);
  font-size: 0.6rem;
  width: 1.1rem;
  height: 1.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #fff;
  display: none;
}
.nav__count__number--heart {
  background-color: rgb(233, 124, 124);
}
.nav__count__number--basket {
  background-color: rgb(121, 187, 101);
}
.nav__count__number--active {
  display: flex;
}/*# sourceMappingURL=navbar.css.map */