header {
  background-color: var(--clr-white);
  border-bottom: 1px solid var(--clr-mid);
  position: relative;
  z-index: 999;
}

nav {
  display: flex;
  padding: 0em 2em;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5em;

  @media (max-width: 600px) {
    padding: 0.5em;

    .nav__flex {
      .nav__logo {
        display: none;
      }
      .searchbar {
        display: none;
      }
    }
  }
}

.nav__top {
  align-items: center;
  justify-content: space-between;
  gap: 1em;
  display: none;

  @media (max-width: 600px) {
    display: flex;
  }
}

.nav__flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.5em;
}

.nav__content {
  display: flex;
  align-items: center;
  gap: 1.5em;
  padding: 0.5em 0;
  flex-shrink: 0;

  @media (max-width: 600px) {
    // gap: 1em;
  }
  @media (max-width: 400px) {
    // gap: 0.6rem;
  }

  &:nth-of-type(1) {
    a:hover,
    button:hover,
    .active {
      font-weight: bold;
      color: rgb(63, 52, 52);
    }
  }

  // prevent error from event.target click handler
  svg {
    pointer-events: none;
  }
}

.nav__logo {
  width: 3em;
  height: 3em;
  flex-shrink: 0;
}

.nav__cloth {
  display: flex;
}

.nav__lists {
  position: absolute;
  left: 0;
  top: calc(100%);
  width: 100%;
  border-bottom: 1px solid var(--clr-mid);
  background-color: var(--clr-white);
  display: none;

  &--active {
    display: block;
  }

  .wrapper {
    padding: 1.5em 2em;
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 4em;

    @media (max-width: 600px) {
      padding: 0.5em 1em;
      gap: 2em;
    }
  }

  &__box {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    width: 100%;

    a:hover {
      text-decoration: underline;
      color: black;
    }
  }
  &__category {
    color: #8a8484;
    user-select: none;
    pointer-events: none;
  }
}

.nav__cloth__btn--clothes--active {
  text-decoration: underline;
}

.nav__count {
  position: relative;

  &__number {
    position: absolute;
    pointer-events: none;
    user-select: none;
    right: -0.5rem;
    top: -0.5rem;
    border: 2px solid rgb(100, 88, 88);
    font-size: 0.6rem;
    width: 1.1rem;
    height: 1.1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: #fff;
    display: none;

    &--heart {
      background-color: rgb(233, 124, 124);
    }

    &--basket {
      background-color: rgb(121, 187, 101);
    }

    &--active {
      display: flex;
    }
  }
}
